<div class="blog-card">
    <div class="row">
        <div class="col-md-4" *ngFor="let item of componentData">
            <a href="{{goToSpecificBlog(item)}}">
            <div class="blog-single-card pointer">
                <div class="blog_card_image"
                    [ngStyle]="{background: 'url('+item.image + ')' + 'no-repeat center / cover,#cccccc'}"></div>
                <div class="date_type" *ngIf="!componentName">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="d-block">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.8333 3.33325H4.16667C3.24619 3.33325 2.5 4.07944 2.5 4.99992V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V4.99992C17.5 4.07944 16.7538 3.33325 15.8333 3.33325Z"
                                            stroke="#151515" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M13.332 1.66675V5.00008" stroke="#151515" stroke-opacity="0.5"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.66797 1.66675V5.00008" stroke="#151515" stroke-opacity="0.5"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2.5 8.33325H17.5" stroke="#151515" stroke-opacity="0.5" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.66797 11.6667H6.6763" stroke="#151515" stroke-opacity="0.5"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10 11.6667H10.0083" stroke="#151515" stroke-opacity="0.5"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.332 11.6667H13.3404" stroke="#151515" stroke-opacity="0.5"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.66797 15H6.6763" stroke="#151515" stroke-opacity="0.5"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10 15H10.0083" stroke="#151515" stroke-opacity="0.5" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.332 15H13.3404" stroke="#151515" stroke-opacity="0.5"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
    
                                </span>
                                <span class="description_14 mt-1 ps-2 color_15_50">{{ item.datePublished | date:'dd MMMM y' }}</span>
                            </div>
                            <div class="d-flex align-items-center margin_left_32">
                                <span class="d-block">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2799_28201)">
                                        <path d="M10.0013 18.3334C14.6037 18.3334 18.3346 14.6025 18.3346 10.0001C18.3346 5.39771 14.6037 1.66675 10.0013 1.66675C5.39893 1.66675 1.66797 5.39771 1.66797 10.0001C1.66797 14.6025 5.39893 18.3334 10.0013 18.3334Z" stroke="#151515" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10 5V10H13.75" stroke="#151515" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2799_28201">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                        
                                </span>
                                <span class="d-block description_14 mt-1 ps-2 color_15_50">
                                    {{ item.createdAt }}
                                </span>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <h4 class="blog_card_heading heading_20">{{item.heading.length > 30 ? item.heading.slice(0, 30) + '...':
                    item.heading}}</h4>
                <div class="description color_15_75">{{item.description.length > 200 ? item.description.slice(0, 200) :
                    item.description}}
                </div>
                <div class="mb-3">
                    <span class="read-more-text pointer" (click)="goToSpecificBlog(item)">Read More</span>
                </div>
            </div>
        </a>
        </div>
    </div>
</div>