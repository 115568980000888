import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'trpx-disney-carousel',
  templateUrl: './disney-carousel.component.html',
  styleUrls: ['./disney-carousel.component.scss']
})
export class DisneyCarouselComponent {
  @Input() slides: any;
  @Input() componentName: any;


  constructor(public router: Router) {

  }
  ngOnInit(): void { }

  // slideConfig = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   infinite: true,
  //   dots: true,
  //   arrows: false,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   variableWidth: false,
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         dots: true,
  //       },
  //     },
  //   ],
  // };
}



 