import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from './service/shared.service';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { BillingDetailsComponent } from './pages/billing-details/billing-details.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { environment } from 'src/environments/environment';
import { RobotsComponent } from './pages/robots/robots.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'trpx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Book Experiences, Activities, Sightseeing Tours & Things To Do - tripXOXO';
  @Input() componentName: string = '';
  @Input() componentData: any;
  showHeader$ = this.sharedService.showHeader$;
  showFooter$ = this.sharedService.showFooter$;

  hideHeader: boolean = false;
  hideFooter: boolean = false;

  constructor(
    private titleService: Title,
    private router: Router,
    private sharedService: SharedService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (!localStorage.getItem('Trip_Country'))
      localStorage.setItem('Trip_Country', '');
    if (!localStorage.getItem('Trip_CountryCode'))
      localStorage.setItem('Trip_CountryCode', '');
    if (!localStorage.getItem('Trip_Currency'))
      localStorage.setItem('Trip_Currency', '');
    if (!localStorage.getItem('Trip_CountryCode'))
      localStorage.setItem('Trip_CountryCode', '');
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;

        if (url == '/') {
          this.titleService.setTitle(
            'tripXOXO - Activities, tours and transfers'
          );
        } else {
          this.titleService.setTitle('Book Experiences, Activities, Sightseeing Tours & Things To Do - tripXOXO');
        }
      }
    });
    this.loadGA4();
    this.loadGTM();

    const port = window.location.port;
    const styleElement = this.renderer.createElement('link');
    styleElement.rel = 'stylesheet';

    let baseUrl = window.location.origin;
    if (baseUrl.includes('indigo')) {
      this.renderer.addClass(document.body, 'indigo');
    } else if(baseUrl.includes('yesrewards')) {
      this.renderer.addClass(document.body, 'yesrewards');
    }

    this.renderer.appendChild(document.head, styleElement);

    this.sharedService.hideHeader$.subscribe((value) => {
      this.hideHeader = value;
    });
    this.sharedService.hideFooter$.subscribe((value) => {
      this.hideFooter = value;
    });
  }

  loadGA4() {
    const ga4Code = environment.ga4Code;
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Code}`;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${ga4Code}');
    `;
    document.head.appendChild(script2);
  }

  loadGTM() {
    const gtmId = environment.gtmId;
    const script1 = document.createElement('script');
    script1.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script1);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noscript);
  }

  capitalizeString(str: any) {
    return str.replace(/\b\w/g, function (match: any) {
      return match.toUpperCase();
    });
  }

  onActivate(event: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sharedService.setShowHeader(
        !(event instanceof LandingPageComponent)
      );
      this.sharedService.setShowFooter(
        !(
          event instanceof BillingDetailsComponent ||
          event instanceof PaymentComponent
        )
      );
      this.sharedService.setHideHeader(
        !(event instanceof RobotsComponent || event instanceof SitemapComponent)
      );
      this.sharedService.setHideFooter(
        !(event instanceof RobotsComponent || event instanceof SitemapComponent)
      );
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
