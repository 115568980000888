import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  private dataSubscription: Subscription = new Subscription;
  isLoginShown = false;

  constructor(private router: Router, private authService: AuthService, private toastr: ToastrService) {
    this.dataSubscription = this.authService.isLoginShown$.subscribe((data) => {
      this.isLoginShown = data;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string | null = this.authService.getToken();
    const currency: string | null = localStorage.getItem('Trip_Currency');
    const country: string | null = localStorage.getItem('Trip_Country');
    const travelAgent: string | null = localStorage.getItem('Trip_Travel_Agent');
    const afiliateId: string | null = localStorage.getItem('afiliateId');
    const customerProfile: string | null = localStorage.getItem('customer_profile');
    const webSource: string | null = localStorage.getItem('white_label_source');
    const coupon: string | null = localStorage.getItem('couponCode');

    const headersConfig: { [name: string]: string | string[] } = {};

    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    if (afiliateId) {
      headersConfig['affiliate'] = JSON.parse(afiliateId);
    }

    if (travelAgent && travelAgent !== 'undefined') {
      headersConfig['Travel-Agent'] = JSON.parse(travelAgent);
    }

    if (customerProfile && customerProfile !== 'undefined') {
      const customer = JSON.parse(customerProfile);
      headersConfig['Source'] = customer?.source || 'WEB';
    }

    if (country) {
      headersConfig['country'] = country;
    }

    if (currency) {
      headersConfig['currency'] = currency;
    }
    if (coupon) {
      headersConfig['coupon'] = coupon;
    }

    if (webSource && webSource !== 'undefined') {
      headersConfig['White-Label-Source'] = webSource;
    }

    request = request.clone({ setHeaders: headersConfig });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (!this.isLoginShown) {
            this.isLoginShown = true;
            this.authService.updateLoginPopShown(true);
            this.authService.updateCustomer('404');
            this.authService.logout();
          }
        } else if (error.status === 400) {
          console.log(error);
        }
        return throwError(error);
      })
    );
  }


  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
