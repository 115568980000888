<ngx-slick-carousel class="testimonial-slider carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <div class="slides">
            <div class="slide-image"
                [ngStyle]="{background: 'url('+ slide.image + ')' + 'no-repeat center / cover,#cccccc'}">
            </div>
            <div class="slider-content">
                <div class="description">
                    {{slide.description}}
                </div>
                <div class="d-flex" *ngIf="slide?.author">
                    <div class="author-image">
                        <img src="{{ slide?.iconUrl }}" class="img-fluid" alt="">
                    </div>
                    <div>
                        <div class="author-name">{{slide?.author}}</div>
                        <div class="author-description">{{slide?.heading}}</div>
                    </div>
                </div>
                <div class="author-name" *ngIf="slide?.diney_heading">
                    {{slide?.diney_heading}}
                </div>
            </div>
        </div>
    </div>
</ngx-slick-carousel>
