import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchResultService } from 'src/app/service/search-results.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/service/home.service';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'trpx-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  experianceSection: boolean = true;
  multidaySection: boolean = false;
  transfersSection: boolean = false;
  searchQuery = '';
  searchForm: any = FormGroup;
  isLoading = false;
  isLoadingFirst = false;
  isLoadingSecond = false;

  activeTab: string = '';
  showSearchComponent: boolean = false;

  airportPickData: any;
  airportDropData: any;
  showPickupAirportName: boolean = false;
  showDropoffAirportName: boolean = false;
  searchBoxListData: any;
  selectedAirportPick: any;
  selectedAirportDrop: any;
  isPickupSelected = false;
  isDropoffSelected = false;
  showMultidayLocation: boolean = false;
  timeoutSec: any;
  scrollOpened = false;

  experiences_id: string = 'experiences';
  multiday_id: string = 'guided-trips';
  transfers_id: string = 'transfers';

  @ViewChild(MatSelect) matSelect!: MatSelect;

  @ViewChild('searchBox') searchBox: ElementRef | undefined;
  private dataSubscription: Subscription = new Subscription();
  private suggestionSubscription: Subscription = new Subscription();
  countryId: any;

  mytime: Date = new Date();

  multiLocation = '';
  multiBooking = '';
  multiDate: any;
  showMultiBooking: any;
  bookingTypeData: any = [];
  today = new Date();

  todayDate = this.datePipe.transform(this.today, 'dd/MM/yy');
  currentTime: any;
  placeholderTexts: string[] = [
    'Search Experiences and Destinations',
    'Burj Khalifa',
    'Universal Studios Singapore',
    'Dubai Village Dubai',
    'YAS Island - Abu Dhabi',
  ];
  currentPlaceholderIndex: number = 0;
  showPlaceholder: boolean = true;
  formSubmitted: boolean = false;

  pickUpItemClick: boolean = false;
  dropItemClick: boolean = false;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private apollo: Apollo,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private searchService: SearchResultService,
    public toastr: ToastrService,
    private homeService: HomeService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    this.dataSubscription = this.homeService.country$.subscribe((data: any) => {
      this.countryId = data;

      this.route.queryParams.subscribe((params) => {
        // Get the 'tab' or 'type' query parameters
        const section = params['section'] ?? params['type']; // Prefer 'tab', fallback to 'type'

        // Set the active tab based on the query parameter
        if (section) {
          this.searchTab(section); // If 'tab' or 'type' is present, set it as active tab
        } else {
          this.searchTab(this.experiences_id); // Default to 'experiences' if neither is provided
        }

        // Handle additional parameters based on the active tab
        switch (this.activeTab) {
          case 'guided-trips':
            this.multidaySearch();
            this.multiLocation = params['location'] ?? ''; // Capture 'location'
            this.multiBooking = params['bookingType'] ?? ''; // Capture 'bookingType'
            this.multiDate = params['date'] ?? ''; // Capture 'date'
            break;

          case 'transfers':
            this.transfersSearch();
            this.selectedAirportPick = params['startaddress'] ?? ''; // Capture 'startaddress'
            this.isPickupSelected = true;
            this.resetSearch(params); // Reset search for 'transfers'
            break;

          default:
            this.experianceSearch();
            this.searchQuery = params['experiance'] ?? ''; // Capture 'experiance'
            this.resetSearch(); // Reset search for 'experiences'
            break;
        }
      });
    });
    this.today.setDate(this.today.getDate() - 0);
    this.dateAdapter.setLocale('en-GB');
    this.bookingTypeData = [
      {
        id: '370',
        categoryName: 'All',
        categoryDisplayName: 'All Adventure',
        description: 'See every adventure you can experience!',
        __typename: 'CategoryType',
      },
      {
        id: '372',
        categoryName: 'Group',
        categoryDisplayName: 'Group Adventure',
        description: 'Join a group for adventures and lasting connections.',
        __typename: 'CategoryType',
      },
      {
        id: '368',
        categoryName: 'Private',
        categoryDisplayName: 'Private Adventure',
        description: 'Explore the world exclusively with friends and family.',
        __typename: 'CategoryType',
      },
      {
        id: '402',
        categoryName: 'Personalized',
        categoryDisplayName: 'Personalized Adventure',
        description:
          'Customize a trip or build your dream adventure from scratch.',
        __typename: 'CategoryType',
      },
    ];
  }

  ngOnInit(): void {
    setInterval(() => {
      this.changePlaceholder();
    }, 3000);
  }

  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
      this.multiDate = this.datePipe.transform(event.date, 'yyyy-MM');
    };
    container.setViewMode('month');
  }

  changePlaceholder() {
    this.showPlaceholder = false;
    this.currentPlaceholderIndex =
      (this.currentPlaceholderIndex + 1) % this.placeholderTexts.length;
    setTimeout(() => {
      this.showPlaceholder = true;
    }, 2000); // Adjust the animation duration as needed
    // this.searchQuery = this.placeholderTexts[this.currentPlaceholderIndex];
  }

  resetSearch(params?: any) {
    this.searchForm = this.fb.group({
      start_address: [params?.startaddress ?? '', [Validators.required]],
      end_address: [params?.endaddress ?? '', [Validators.required]],
      num_passengers: [params?.numpassengers ?? 1, [Validators.required]],
      mode: [params?.mode === 'true' ? true : false],
      pickup_date: [params?.pickupdate ?? '', [Validators.required]],
      pickup_time: [params?.pickuptime ?? null, [Validators.required]],
      return_pickup_date: [params?.returnpickupdate ?? ''],
      return_pickup_time: [params?.returnpickuptime ?? ''],
      country_id: this.countryId,
    });

    this.searchForm.get('mode').valueChanges.subscribe((isRoundTrip: any) => {
      const returnDateControl = this.searchForm.get('return_pickup_date');
      const returnTimeControl = this.searchForm.get('return_pickup_time');

      if (isRoundTrip) {
        returnDateControl.setValidators([Validators.required]);
        returnTimeControl.setValidators([Validators.required]);
      } else {
        returnDateControl.clearValidators();
        returnTimeControl.clearValidators();
      }

      returnDateControl.updateValueAndValidity();
      returnTimeControl.updateValueAndValidity();
    });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  searchFn(form: FormGroup) {
    this.markFormGroupTouched(this.searchForm);
    this.formSubmitted = true;
    if (this.pickUpItemClick && this.dropItemClick) {
      if (form.valid) {
        this.isLoading = true;
        this.getTransferLog(form.value.start_address);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate([`/search-results`], {
            queryParams: {
              type: 'transfers',
              startaddress: form.value.start_address,
              endaddress: form.value.end_address,
              numpassengers: form.value.num_passengers,
              mode: form.value.mode,
              pickupdate: this.datePipe.transform(
                form.value.pickup_date,
                'yyyy-MM-dd'
              ),
              pickuptime: this.formatTimeTo24Hour(form.value.pickup_time),
              // returnpickupdate: this.datePipe.transform(form?.value?.return_pickup_date, 'yyyy-MM-dd'),
              ...(form.value.return_pickup_date && form.value.return_pickup_time
                ? {
                    returnpickupdate: this.datePipe.transform(
                      form.value.return_pickup_date,
                      'yyyy-MM-dd'
                    ),
                    returnpickuptime: this.formatTimeTo24Hour(
                      form.value.return_pickup_time
                    ),
                  }
                : {}),
            },
          })
        );
      } else {
        // this.toastr.warning("Please fill all the fields");
      }
    }
  }

  getTransferLog(destinationName: any) {
    this.searchService.getTransferLog(destinationName).subscribe((res) => {
      let data = res;
    });
  }

  formatTimeTo24Hour(time12Hour: string): string {
    const [time, period] = time12Hour.split(' ');

    let [hours, minutes] = time.split(':');
    hours =
      period === 'PM' ? String(Number(hours) + 12) : hours.padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  selectOption(categoryName: string) {
    this.multiBooking = categoryName;
  }

  openSelect(event: any) {
    if (event == 'All') {
      this.showMultiBooking = 'All Adventure';
    } else if (event == 'Group') {
      this.showMultiBooking = 'Group Adventure';
    } else if (event == 'Private') {
      this.showMultiBooking = 'Private Adventure';
    } else if (event == 'Personalized') {
      this.showMultiBooking = 'Personalized Adventure';
    }
  }

  openMatselect() {
    this.matSelect.open();
  }

  // Function to handle tab switch and navigation
  searchTab(sectionId: string) {
    if (sectionId === 'experiences') {
      // For 'experiences', navigate to the base URL without any query parameters
      this.router.navigate(['/']);
    } else {
      // For other tabs like 'transfers' or 'guided-trips', append the section as a query parameter
      this.router.navigate([`/`], { queryParams: { section: sectionId } });
    }
    this.activeTab = sectionId;

    // Set visibility flags based on the active tab
    this.experianceSection = sectionId === this.experiences_id;
    this.multidaySection = sectionId === this.multiday_id;
    this.transfersSection = sectionId === this.transfers_id;

    // Reset other states when switching tabs
    this.showSearchComponent = false;
    this.showPickupAirportName = false;
    this.showDropoffAirportName = false;
    this.isInputTouched = false;
    this.formSubmitted = false;
    this.resetSearch();
  }

  // Wrapper functions for each tab's search
  experianceSearch() {
    this.searchTab(this.experiences_id);
  }

  multidaySearch() {
    this.searchTab(this.multiday_id);
  }

  transfersSearch() {
    this.searchTab(this.transfers_id);
  }

  popupDisable() {
    this.showPickupAirportName = false;
    this.showDropoffAirportName = false;
    this.showMultidayLocation = false;
  }

  onIncrement(): void {
    let guests = this.searchForm.value.num_passengers;
    if (guests < 20) {
      this.searchForm.get('num_passengers').setValue(++guests);
    }
  }

  onDecrement(): void {
    let guests = this.searchForm.value.num_passengers;
    if (guests > 1) {
      this.searchForm.get('num_passengers').setValue(--guests);
    }
  }

  isInputTouched: boolean = false;

  experianceSearchParam() {
    this.isInputTouched = true;
    if (this.nullCheck(this.searchQuery)) {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() =>
          this.router.navigate([`/search-results`], {
            queryParams: { type: this.activeTab, experiance: this.searchQuery },
          })
        );
      this.trackSearch();
    } else {
      // this.toastr.warning("Please Search Destination");
    }
  }

  trackSearch(): void {
    console.log('Attempting to push search event');
    if (window && window.gtag) {
      window.gtag('event', 'search', {
        search_term: this.searchQuery,
        location: 'home_page',
      });
      console.log('search', {
        search_term: this.searchQuery,
        location: 'home_page',
      });
    } else {
      console.error('gtag function not found');
    }
  }

  multidaySearchParam() {
    this.isInputTouched = true;
    if (
      this.nullCheck(this.multiLocation) ||
      this.nullCheck(this.multiBooking) ||
      this.nullCheck(this.multiDate)
    ) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([`/search-results`], {
          queryParams: {
            type: 'multiday',
            destination: this.multiLocation,
            category: this.multiBooking,
            month: this.multiDate,
          },
        })
      );
      this.getSearchGuidedLog(this.multiLocation);
    } else {
      // this.toastr.warning("Please Search Destination");
    }
  }

  getSearchGuidedLog(destinationName: any) {
    this.searchService.getGuidedLog(destinationName).subscribe((res) => {
      let data = res;
    });
  }

  showSearchResult() {
    this.showSearchComponent = true;
  }

  nullCheck(vari: any): boolean {
    // Check if the variable is not undefined, not null, and not an empty string
    if (vari !== undefined && vari !== null && vari !== '') {
      return true; // Return true if the variable has a value
    } else {
      return false; // Return false if the variable is undefined, null, or an empty string
    }
  }

  saverange(event: any) {
    clearTimeout(this.timeoutSec);
    this.timeoutSec = setTimeout(() => {
      this.getProductDetails(event);
    }, 500);
  }

  getProductDetails(product: string) {
    this.suggestionSubscription = this.apollo
      .watchQuery({
        query: this.searchService.searchBoxList,
        variables: {
          variable1: product,
        },
      })
      .valueChanges.subscribe(({ data, error }: any) => {
        this.searchBoxListData = data?.product;
      });
  }

  airportPickName(event: any) {
    if (event !== null && event !== undefined) {
      // Adding null check for event
      clearTimeout(this.timeoutSec);
      if (!this.isPickupSelected && this.selectedAirportPick) {
        this.pickUpItemClick = false;
        this.formSubmitted = false;
        this.timeoutSec = setTimeout(() => {
          this.getAirportPickDetails(event);
        }, 500);
      } else {
        this.isPickupSelected = false;
        this.showPickupAirportName = false;
      }
    } else {
      this.showPickupAirportName = false;
    }
  }

  airportDropName(event: any) {
    if (event !== null && event !== undefined) {
      // Adding null check for event
      clearTimeout(this.timeoutSec);
      if (!this.isDropoffSelected && this.selectedAirportDrop) {
        this.dropItemClick = false;
        this.formSubmitted = false;
        this.timeoutSec = setTimeout(() => {
          this.getAirportDropDetails(event);
        }, 500);
      } else {
        this.isDropoffSelected = false;
        this.showDropoffAirportName = false;
      }
    } else {
      this.showDropoffAirportName = false;
    }
  }

  multidayLocation(event: any) {
    clearTimeout(this.timeoutSec);
    this.timeoutSec = setTimeout(() => {
      this.getProductDetails(event);
    }, 500);
    this.showMultidayLocation = true;
  }

  getAirportPickDetails(query: any) {
    this.isLoadingFirst = true;
    this.searchService.getAirports(query).subscribe((result) => {
      this.airportPickData = result?.airport_names;
      if (this.airportPickData?.length > 0) {
        this.showPickupAirportName = true;
      } else if (this.airportPickData?.length == 0) {
        this.showPickupAirportName = false;
      }
      this.isLoadingFirst = false;
    });
  }

  getAirportDropDetails(query: any) {
    this.isLoadingSecond = true;
    this.searchService.getAirports(query).subscribe((result) => {
      this.airportDropData = result?.airport_names;
      if (this.airportDropData?.length > 0) {
        this.showDropoffAirportName = true;
      } else if (this.airportDropData?.length == 0) {
        this.showDropoffAirportName = false;
      }
      this.isLoadingSecond = false;
    });
  }
  selectedAirportPickIcon: any;
  itemSelect(item: any) {
    this.selectedAirportPick = item.name;
    this.selectedAirportPickIcon = item.icon_url;
    this.showPickupAirportName = false;
    this.isPickupSelected = true;
    this.pickUpItemClick = true;
    clearTimeout(this.timeoutSec);
  }
  selectedAirportDropIcon: any;
  itemDropSelect(item: any) {
    this.selectedAirportDrop = item.name;
    this.selectedAirportDropIcon = item.icon_url;
    this.showDropoffAirportName = false;
    this.isDropoffSelected = true;
    this.dropItemClick = true;
    clearTimeout(this.timeoutSec);
  }

  multiCountry(item: any) {
    this.multiLocation = item;
    this.showMultidayLocation = false;
  }

  multiCategory(item: any) {
    this.multiLocation = item;
    this.showMultidayLocation = false;
  }

  multiDestination(item: any) {
    this.multiLocation = item;
    this.showMultidayLocation = false;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (
      this.searchBox &&
      !this.searchBox.nativeElement.contains(event.target)
    ) {
      this.showSearchComponent = false;
      this.showMultidayLocation = false;
      this.showPickupAirportName = false;
      this.showDropoffAirportName = false;
    }
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    clearTimeout(this.timeoutSec);
    this.suggestionSubscription.unsubscribe();
  }

  isSameDay(dateString1: any, dateString2: any): boolean {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  dateValueChange(date: any) {
    this.currentTime = null;
    this.searchForm.get('pickup_time').reset();
    setTimeout(() => {
      if (this.isSameDay(date, this.today))
        this.currentTime = this.datePipe.transform(this.today, 'hh:mm a');
      else this.currentTime = '12:00 AM';
    }, 500);
  }
}
