<div id="loading-wrapper" *ngIf="firstLoading">
    <div class="loader-img">
        <img *ngIf="!loaderGif" src="./assets/images/loader-1.gif" alt="">
        <img *ngIf="loaderGif && indigoLabel" src="./assets/images/loader-2.gif" alt="">
        <img *ngIf="loaderGif && yesrewardLabel" src="./assets/images/loader-3.gif" alt="">
    </div>
</div>
<div class="trpx-grind-landing-page">
    <trpx-header class="position-relative" [componentData]="headerData" [componentName]="componentName"></trpx-header>
</div>
<!-- GridLanding Section  -->
<div class="trpx-activity-cards GridLanding" *ngIf="landingCardsGrid">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mb-3" *ngIf="pageNameHeading">
                <div class="heading_24">
                    {{pageNameHeading}}
                </div>
            </div>
            <div class="col-6 col-md-4" *ngFor="let card of landingCardsGrid">

                <div class="trpx-activity-card">
                    <a href="{{openLink(card?.product?.landing_slug)}}" target="_blank">
                        <div *ngIf="card?.product?.product_banners[0]?.length" class="trpx-activity-image"
                            [ngStyle]="{background: 'url('+ card?.product?.product_banners[0] + ')' + 'no-repeat center / cover,#cccccc'}">
                            <div class="starred-div heading_16" *ngIf="card?.product?.tag">{{card?.product?.tag}}</div>
                        </div>
                        <div *ngIf="!card?.product?.product_banners[0]?.length" class="trpx-activity-image"
                            [ngStyle]="{background: 'url('+ 'assets/images/empty.webp' + ')' + 'no-repeat center / cover,#cccccc'}">
                            <div class="starred-div heading_16" *ngIf="card?.product?.tag">{{card?.product?.tag}}</div>
                        </div>
                        <div class="trpx-activity-content pointer d-md-grid">
                            <div class="d-block">
                                <div class="heading_20 color_15">
                                    {{card?.product?.name?.length<80 ? card?.product?.name: card?.product?.name.slice(0,
                                        80) + '...' }} </div>
                                        <div class="description_16 color_15"
                                            [innerHTML]="card?.product?.product_description_set?.description?.length<140 ? card?.product?.product_description_set?.description : card?.product?.product_description_set?.description.slice(0, 140) + '...'">
                                        </div>
                                </div>

                                <div class="logo_images mb-2">
                                    <img *ngFor="let item of card?.product?.product_icons" class="me-1 me-md-3"
                                        src="{{item}}" alt="">
                                </div>
                                <div class="position_bottom_price">
                                    <div class="">
                                        <div class="mb-1 description_14">Total Price</div>
                                        <div class="prices heading_24 color_15" *ngIf="card?.product?.price">
                                            {{card?.product?.currency_symbol}}{{card?.product?.price}}
                                            <span class="slashed description_14 me-2"
                                                *ngIf="card?.product?.slashed_price">{{card?.product?.currency_symbol}}{{card?.product?.slashed_price}}</span>
                                            <span class="description_14">Per person</span>
                                        </div>
                                    </div>
                                    <div class="">
                                        <a class="btn btn-primary btn-transparent"> Book Now </a>
                                    </div>
                                </div>
                            </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- GridLanding Section  -->

<!-- ListLanding Section  -->
<div class="trpx-activity-cards ListLanding" *ngIf="landingCardsList">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-9 mb-3" *ngIf="pageNameHeading">
                <div class="heading_24">
                    {{pageNameHeading}}
                </div>
            </div>
            <div class="col-12 col-md-9" *ngFor="let card of landingCardsList">

                <div class="trpx-activity-card">
                    <a class="d-md-flex" href="{{openLink(card?.product?.landing_slug)}}" target="_blank">
                        <div class="image_left">
                            <div *ngIf="card?.product?.product_banners[0]?.length" class="trpx-activity-image"
                                [ngStyle]="{background: 'url('+ card?.product?.product_banners[0] + ')' + 'no-repeat center / cover,#cccccc'}">
                                <div class="starred-div heading_16" *ngIf="card?.product?.tag">{{card?.product?.tag}}
                                </div>
                            </div>
                            <div *ngIf="!card?.product?.product_banners[0]?.length" class="trpx-activity-image"
                                [ngStyle]="{background: 'url('+ 'assets/images/empty.webp' + ')' + 'no-repeat center / cover,#cccccc'}">
                                <div class="starred-div heading_16" *ngIf="card?.product?.tag">{{card?.product?.tag}}
                                </div>
                            </div>
                        </div>
                        <div class="trpx-activity-content pointer d-md-grid">
                            <div class="d-block">
                                <div class="heading_20 color_15">
                                    {{card?.product?.name?.length<80 ? card?.product?.name: card?.product?.name.slice(0,
                                        80) + '...' }} </div>
                                        <div class="description_16 color_15" [innerHTML]="card?.product?.product_description_set?.description?.length < 350 ? 
                                card?.product?.product_description_set?.description : 
                                card?.product?.product_description_set?.description.slice(0, 350) + '...'">
                                        </div>
                                </div>
                                <div class="logo_images">
                                    <img *ngFor="let item of card?.product?.product_icons" class="me-3" src="{{item}}"
                                        alt="">
                                </div>
                                <div class="position_bottom_price">
                                    <div class="">
                                        <div class="mb-1 description_14">Total Price</div>
                                        <div class="prices heading_24 color_15" *ngIf="card?.product?.price">
                                            {{card?.product?.currency_symbol}}{{card?.product?.price}}
                                            <span class="slashed description_14 me-2"
                                                *ngIf="card?.product?.slashed_price">{{card?.product?.currency_symbol}}{{card?.product?.slashed_price}}</span>
                                            <span class="description_14">Per person</span>
                                        </div>
                                    </div>
                                    <div class="">
                                        <a class="btn btn-primary btn-transparent"> Book Now </a>
                                    </div>
                                </div>
                            </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ListLanding Section  -->