import { AsyncPipe, CommonModule, DatePipe, NgFor } from "@angular/common";
import { NgModule } from "@angular/core";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TestimonialCardComponent } from './carousels/testimonial-card/testimonial-card.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { OffersCardComponent } from './carousels/offers-card/offers-card.component';
import { DestinationSingleCarouselsComponent } from './carousels/destination-single-carousels/destination-single-carousels.component';
import { ExperianceTabComponent } from './experiance-tab/experiance-tab.component';
import { ExperianceContentCardComponent } from './experiance-content-card/experiance-content-card.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SearchBoxComponent } from './search-box/search-box.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BlogCardComponent } from "./blog-card/blog-card.component";
import { DestinationCardComponent } from "./carousels/destination-card/destination-card.component";
import { ActivityCardComponent } from "./activity-card/activity-card.component";
import { ActivityCardCarouselComponent } from "./carousels/activity-card-carousel/activity-card-carousel.component";
import { VerticalCarouselComponent } from './carousels/vertical-carousel/vertical-carousel.component';
import { ProductReviewComponent } from './product-review/product-review.component';
import { AccordionComponent } from './accordion/accordion.component';
import { PersonalInfoComponent } from './profile-component/personal-info/personal-info.component';
import { BookingComponent } from './profile-component/booking/booking.component';
import { MyWalletComponent } from './profile-component/my-wallet/my-wallet.component';
import { PaymentMethodsComponent } from './profile-component/payment-methods/payment-methods.component';
import { PromoCodesComponent } from './profile-component/promo-codes/promo-codes.component';
import { WishlistComponent } from './profile-component/wishlist/wishlist.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ToastrModule } from "ngx-toastr";
import { SearchSuggestionsComponent } from './search-suggestions/search-suggestions.component';
import { GroupBookingComponent } from './group-booking/group-booking.component';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { WhyChooseComponent } from './carousels/why-choose/why-choose.component';
import { AddWalletModalComponent } from './profile-component/add-wallet-modal/add-wallet-modal.component';
import { NumberDirective } from "./directive/number-only.directive";
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { BlogNewsTabComponent } from './blog-news-tab/blog-news-tab.component';
import { AlphabeticDirective } from "../directive/alpha.directive";
import { NumericDirective } from "../directive/number.directive";
import { AttractionSliderComponent } from './carousels/attraction-slider/attraction-slider.component';
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { AffiliateWithdrawModalComponent } from './profile-component/affiliate-withdraw-modal/affiliate-withdraw-modal.component';
import { CustomSelectBoxComponent } from './custom-select-box/custom-select-box.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AccomodationFormComponent } from './accomodation-form/accomodation-form.component';
import { AddonFormComponent } from './addon-form/addon-form.component';
import { FlightSelectionComponent } from './flight-selection/flight-selection.component';
import { CapitaliseAndReplaceUnderscorePipe } from "../pipes/capitalise-and-replace-underscore.pipe";
import { DashboardComponent } from './profile-component/dashboard/dashboard.component';
import { DisneyCardComponent } from './disney-card/disney-card.component';
import { DisneyCarouselComponent } from './carousels/disney-carousel/disney-carousel.component';
import { CapitalizePipe } from "../pipes/capitalise.pipe";


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        BlogCardComponent,
        TestimonialCardComponent,
        DestinationCardComponent,
        OffersCardComponent,
        DestinationSingleCarouselsComponent,
        ActivityCardComponent,
        ExperianceTabComponent,
        ExperianceContentCardComponent,
        HeaderMenuComponent,
        SearchBoxComponent,
        ActivityCardCarouselComponent,
        VerticalCarouselComponent,
        ProductReviewComponent,
        AccordionComponent,
        PersonalInfoComponent,
        BookingComponent,
        MyWalletComponent,
        PaymentMethodsComponent,
        PromoCodesComponent,
        WishlistComponent,
        SignUpComponent,
        SignInComponent,
        SearchSuggestionsComponent,
        GroupBookingComponent,
        WhyChooseComponent,
        AddWalletModalComponent,
        NumberDirective,
        StarRatingComponent,
        DynamicFormComponent,
        BlogNewsTabComponent,
        AlphabeticDirective,
        NumericDirective,
        AttractionSliderComponent,
        AffiliateWithdrawModalComponent,
        CustomSelectBoxComponent,
        AccomodationFormComponent,
        AddonFormComponent,
        FlightSelectionComponent,
        CapitaliseAndReplaceUnderscorePipe,
        DashboardComponent,
        DisneyCardComponent,
        DisneyCarouselComponent,
        CapitalizePipe
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        SlickCarouselModule,
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        TooltipModule.forRoot(),
        NgxSliderModule,
        ToastrModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMatTimepickerModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatProgressBarModule,
        NgFor,
        AsyncPipe,
    ],
    providers: [DatePipe, BsDatepickerConfig],
    exports: [
        HeaderComponent,
        FooterComponent,
        BlogCardComponent,
        TestimonialCardComponent,
        DestinationCardComponent,
        OffersCardComponent,
        DestinationSingleCarouselsComponent,
        ActivityCardComponent,
        ExperianceTabComponent,
        ExperianceContentCardComponent,
        ActivityCardCarouselComponent,
        HeaderMenuComponent,
        SearchBoxComponent,
        VerticalCarouselComponent,
        ProductReviewComponent,
        AccordionComponent,
        PersonalInfoComponent,
        BookingComponent,
        MyWalletComponent,
        PaymentMethodsComponent,
        PromoCodesComponent,
        WishlistComponent,
        SignUpComponent,
        SignInComponent,
        SearchSuggestionsComponent,
        GroupBookingComponent,
        WhyChooseComponent,
        AddWalletModalComponent,
        NumberDirective,
        StarRatingComponent,
        DynamicFormComponent,
        BlogNewsTabComponent,
        AlphabeticDirective,
        NumericDirective,
        AttractionSliderComponent,
        AffiliateWithdrawModalComponent,
        CustomSelectBoxComponent,
        AccomodationFormComponent,
        AddonFormComponent,
        FlightSelectionComponent,
        CapitaliseAndReplaceUnderscorePipe,
        DashboardComponent,
        DisneyCardComponent,
        DisneyCarouselComponent,
        CapitalizePipe
    ]
})
export class SharedModule {
}