<div *ngIf="!['HomeComponent'].includes(componentName)" class="header_body position-relative"
    [ngStyle]="{background: 'url(' + componentData?.image + ')' + 'no-repeat center / cover,#cccccc'}">
    <trpx-header-menu *ngIf="['LandingComponent'].includes(componentName)" [componentName]="componentName"
        [landingLogo]="componentData?.logo_url"></trpx-header-menu>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-xl-9"
                *ngIf="['DistributorComponent','PartnersComponent','BlogComponent', 'NewsComponent','AboutUsComponent', 'AttractionPassComponent'].includes(componentName)">
                <div class="heading_58 text-capitalize">
                    {{componentData?.heading}}
                </div>
                <!-- <div class="description_18 text-capitalize" *ngIf="['HomeComponent'].includes(componentName)">
                    {{componentData?.subHeading}}
                </div> -->
            </div>
            <div class="col-md-11 col-xl-11" *ngIf="['LandingComponent'].includes(componentName)">
                <div class="heading_52 text-capitalize text-white text-center mb-3">
                    {{componentData?.heading}}
                </div>
                <div class="description_24 text-capitalize text-white text-center">
                    {{componentData?.subheading}}
                </div>
            </div>
            <div class="col-md-12" *ngIf="['SearchResultsComponent','AboutUsComponent'].includes(componentName)">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="breadcrumb_item d-flex" *ngIf="breadCrumbData?.length">
                        <div class="text-white">Home</div>
                        <div class="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14"
                                fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355L1.35355 13.3536C1.15829 13.5488 0.841709 13.5488 0.646447 13.3536C0.451184 13.1583 0.451184 12.8417 0.646447 12.6464L6.29289 7L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
                                    fill="white" />
                            </svg>
                        </div>
                        <div class="text-white heading_16" *ngIf="['AboutUsComponent'].includes(componentName)">
                            {{breadCrumbData}}</div>
                        <div class="text-white heading_16" *ngIf="['SearchResultsComponent'].includes(componentName)">
                            Search results for “{{breadCrumbData}}”</div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 col-xl-9" *ngIf="['DisneyComponent'].includes(componentName)">
                <div class="heading_16 text-white text-center mb-1">
                    {{componentData?.smallheading}}
                </div>
                <div class="heading_52 text-capitalize text-white text-center mb-2">
                    {{componentData?.heading}}
                </div>
                <div class="heading_30 text-capitalize text-white text-center">
                    {{componentData?.subheading}}
                </div>
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn btn-primary btn-orange">
                        Enquire Now
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="['HomeComponent'].includes(componentName)" class="header_body home_page position-relative">
    <!-- <trpx-header-menu></trpx-header-menu> -->
    <div class="background-video">
        <img fetchpriority="high" decoding="async" data-nimg="1" class="image_bann d-none d-md-block"
            style="color:transparent;max-width:100%;height:auto;" src="./assets/images/home-banner-video.webp">
        <img fetchpriority="high" decoding="async" data-nimg="1" class="image_bann d-md-none d-block"
            style="color:transparent;max-width:100%;height:auto;" src="./assets/images/home-banner-mob-video.webp">
        <video #videoPlayer class="d-none d-md-block" autoplay loop no-user-gesture-required muted width="100%">
            <source src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/video/Experiance3.webm"
                type="video/webm">
        </video>
        <video #videoPlayerMobile class="d-md-none d-block" autoplay playsinline loop no-user-gesture-required muted
            width="100%">
            <source
                src="https://tripxoxo-prod.s3.ap-south-1.amazonaws.com/tripxoxo_prod/general/video/Experience-mobile.webm"
                type="video/webm">
        </video>
        <div class="home_page_content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10 col-xl-9">
                        <div class="heading_58 text-capitalize">
                            {{componentData?.heading}}
                        </div>
                        <div class="description_18 text-capitalize">
                            {{componentData?.subHeading}}
                        </div>
                    </div>
                    <div class="col-md-10 d-md-block d-none">
                        <trpx-search-box></trpx-search-box>
                    </div>
                    <div class="col-md-12 home_content">
                        <div class="d-none d-md-flex align-items-center justify-content-center text-white">
                            <div class="d-flex justify-content-center align-items-center mb-2 mb-md-0 me-md-3">
                                <div>
                                    <img src="./assets/icons/Star.svg" class="img-star" alt="">
                                </div>
                                <div class="ms-2">4.8 (Review)</div>
                            </div>
                            <img class="d-none d-md-block" src="./assets/icons/verticle-line.webp" alt="">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="d-flex align-items-center me-2 mx-md-3">
                                    <div><img src="./assets/icons/support.svg" alt=""></div>
                                    <div class="ms-2">Secure Payment</div>
                                </div>
                                <img src="./assets/icons/verticle-line.webp" alt="">
                                <div class="d-flex align-items-center ms-2 ms-md-3">
                                    <div><img src="./assets/icons/secure.svg" alt=""></div>
                                    <div class="ms-2">24*7 Support</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>