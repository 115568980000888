import { Component, Input } from '@angular/core';

@Component({
  selector: 'trpx-disney-card',
  templateUrl: './disney-card.component.html',
  styleUrls: ['./disney-card.component.scss']
})
export class DisneyCardComponent {
  @Input() disneyCards: any;

  slideConfig = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 2000,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            dots: true,
            variableWidth: false,
          },
        },
      ],
    };
}
