<div class="trpx-login-modal-header modal-header" *ngIf="!fromPayment">
    <h4 class="modal-title pull-left heading_40">Sign Up</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="trpx-login-modal-body modal-body" *ngIf="!fromPayment">
    <div class="container">
        <div class="row">
            <div class="col-md-12" *ngIf="!otpVerification && !showForm">
                <form [formGroup]="otpGenerationForm" (ngSubmit)="generateOtp()">
                    <div class="col-12 position-relative">
                        <!-- <mat-form-field class="mat_select_field_country">
                            <select matNativeControl [(ngModel)]="selectedDial" name="country"
                                formControlName="country_code">
                                <option *ngFor="let item of countryData" [value]="item.dial_code">
                                    {{ item.dial_code }}
                                </option>
                            </select>
                        </mat-form-field> -->
                        <div class="custom_selet_component">
                            <trpx-custom-select-box
                                (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                        </div>
                        <mat-form-field class="mat_field_width" appearance="outline">
                            <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                            <input type="tel" numbersOnly matInput placeholder="123-456-7890"
                                class="mat_field_mobile_number" required formControlName="mobile_number">
                        </mat-form-field>
                    </div>

                    <small class="text-warning mt-1" *ngIf="isRegistered">Already registered! <a
                            class="fw-bolder pointer blue_link text-warning" (click)="signIn()">Login</a> to
                        continue.</small>

                    <div class="row">
                        <div class="col-md-12 mt-3 mb-2 text-center">
                            <button type="submit" class="btn btn-primary button-green w-100">
                                Proceed
                            </button>

                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12" *ngIf="otpVerification && !signUpDetails">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <div class="text-center">
                            <div class="text-center color_13 heading_24 mb-3">Verify WhatsApp Number</div>
                            <p class="color_20 mb-4">Enter OTP Sent to your WhatsApp Number to verify and
                                proceed further
                            </p>
                        </div>
                        <form [formGroup]="otpVariForm" (ngSubmit)="otpVerify()">
                            <div class="row justify-content-center">
                                <div class="col-md-12 text-center">
                                    <div class="form-outline mb-4">
                                        <div class="inputfield" formArrayName="otpFormArray">
                                            <input type="number" maxlength="1" min="0" max="9" class="otp_input input"
                                                required pattern="[0-9]*"
                                                *ngFor="let input of otpInputs.controls; let i=index"
                                                [formControlName]="i" (input)="nextElem($event,i)" autocomplete="off"
                                                id="otpNum{{i}}" (keydown)="disableArrowKeys($event)" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-8 col-md-7 description_14">
                                    Check WhatsApp for your OTP!
                                </div>
                                <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow!='00:00'">
                                    Resend in {{timeToShow}}
                                </div>
                                <div class="col-4 col-md-5 text-end description_14" *ngIf="timeToShow=='00:00'">
                                    <span (click)="resendOtp()" class="pointer color_00A651 text-decoration-underline"
                                        tabindex="-1">Resend
                                        OTP </span>
                                </div>

                                <div class="col-md-12 mt-4 text-center">
                                    <button type="submit" class="btn btn-primary button-green w-100" tabindex="0"
                                        id="proceed-button">
                                        Proceed</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="signUpDetails || showForm">
                <div class="row justify-content-center">
                    <div class="col-md-12 mt-3">
                        <div class="text-center">
                            <p class="heading_18 color_13 mb-3"><span *ngIf="isNew">Number is not registered.
                                </span>Enter following details to create an account</p>
                        </div>
                        <div>
                            <form [formGroup]="signUpForms">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label> First Name</mat-label>
                                            <input matInput appAlphabetic placeholder="Enter Your First Name" required
                                                formControlName="first_name" maxlength="20">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label> Last Name</mat-label>
                                            <input matInput appAlphabetic placeholder="Enter Your Last Name" required
                                                formControlName="last_name" maxlength="20">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input matInput placeholder="Enter your email ID" required
                                                formControlName="email">
                                            <mat-error style="margin-top: -10px;margin-bottom: 16px;" *ngIf="signUpForms.get('email')?.hasError('required')">
                                                Email is required
                                            </mat-error>
                                            <mat-error style="margin-top: -10px;margin-bottom: 16px;" *ngIf="signUpForms.get('email')?.hasError('email')">
                                                Please enter a valid email address
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                    <!-- <div class="col-12">
                                        <mat-form-field class="mat_date_field_width mat_date_field_fullwidth"
                                            appearance="outline">
                                            <mat-label>Date of Birth</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="dob"
                                                [max]="minAge" changeDateFormat>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <mat-error *ngIf="signUpForms.controls['dob'].hasError('invalidAge')">
                                            Minimum age required: 18 years old
                                        </mat-error>
                                    </div> -->
                                    <!-- <div class="col-12" *ngIf="!isAgent">
                                        <mat-form-field class="mat_field_width mat_date_field_fullwidth"
                                            appearance="outline">
                                            <mat-label>Gender</mat-label>
                                            <select class="mat_custom_select" matNativeControl name="gender"
                                                formControlName="gender">
                                                <option *ngFor="let item of genders" [value]="item.genderName">
                                                    {{item.genderName}}
                                                </option>
                                            </select>
                                        </mat-form-field>
                                    </div> -->
                                </div>
                                <div class="row" *ngIf="!isAgent">
                                    <div class="col-md-12 mt-4 text-center">
                                        <button type="button" (click)="signUpFormSubmit()"
                                            [disabled]="formSubmit || signUpForms.invalid"
                                            class="btn btn-primary button-green w-100">
                                            Register</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="isAgent">
                            <form [formGroup]="agentForms">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label> Company Name</mat-label>
                                            <input matInput appAlphabetic placeholder="Enter Company Name" required
                                                formControlName="company_name">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label> Address</mat-label>
                                            <textarea matInput placeholder="Enter Address" required
                                                formControlName="address" maxlength="200"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width_select">
                                            <input type="text" placeholder="Country" aria-label="Country" matInput
                                                [matAutocomplete]="auto" formControlName="country"
                                                (input)="getCountryByType(agentForms.value.country)">
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option *ngFor="let option of countryList"
                                                    [value]="option.country_name">
                                                    {{ option.country_name }}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>State</mat-label>
                                            <input matInput placeholder="Enter State" required formControlName="state">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="Enter City" required formControlName="city">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>Zip Code</mat-label>
                                            <input matInput placeholder="Enter Zip Code" required
                                                formControlName="zip_code">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>Pancard No</mat-label>
                                            <input matInput placeholder="Enter Pancard No" required
                                                formControlName="pancard_no">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <mat-form-field class="mat_field_width" appearance="outline">
                                            <mat-label>GST No</mat-label>
                                            <input matInput placeholder="Enter GST No" required
                                                formControlName="gst_no">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mt-4 text-center">
                                        <button type="button" (click)="signUpFormSubmit(true)" [disabled]="formSubmit"
                                            class="btn btn-primary button-green w-100">
                                            <img class="me-1" *ngIf="isLoading" width="15"
                                                src="./assets/images/loading-gif.gif" loading="lazy" alt="loader">
                                            Register</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12" *ngIf="(signUpDetails || showForm) && fromPayment">
    <div class="trpx-traveller-details">
        <form [formGroup]="signUpForms" (ngSubmit)="signUpFormSubmit()">
            <div class="row">
                <div class="col-12 col-md-6 position-relative">
                    <!-- <mat-form-field class="mat_select_field_country">
                        <mat-select matNativeControl formControlName="country_code">
                            <mat-option *ngFor="let item of countryData" [value]="item.dial_code">
                                {{ item.dial_code }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <div class="custom_selet_component">
                        <trpx-custom-select-box
                            (countryCodeEvent)="receiveCountryCode($event)"></trpx-custom-select-box>
                    </div>

                    <mat-form-field class="mat_field_width" appearance="outline">
                        <mat-label class="mat_field_mobile_label">WhatsApp Number</mat-label>
                        <input type="tel" matInput appNumeric placeholder="123-456-7890" class="mat_field_mobile_number"
                            required formControlName=" ">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="mat_field_width" appearance="outline">
                        <mat-label> First Name</mat-label>
                        <input matInput appAlphabetic placeholder="Enter Your First Name" required
                            formControlName="first_name" maxlength="20">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="mat_field_width" appearance="outline">
                        <mat-label> Last Name</mat-label>
                        <input matInput appAlphabetic placeholder="Enter Your Last Name" required
                            formControlName="last_name" maxlength="20">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="mat_field_width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Enter your email ID" required formControlName="email">
                        <mat-error class="mt-3" *ngIf="signUpForms.get('email')?.hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error class="mt-3" *ngIf="signUpForms.get('email')?.hasError('email')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>

                </div>
                <!-- <div class="col-12 col-md-6">
                    <mat-form-field class="mat_date_field_width mat_date_field_fullwidth" appearance="outline">
                        <mat-label>Date of Birth</mat-label>
                        <input matInput [matDatepicker]="picker" [max]="minAge" formControlName="dob"
                            changeDateFormat>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div> -->
                <!-- <div class="col-12 col-md-6">
                    <mat-form-field class="mat_field_width mat_date_field_fullwidth" appearance="outline">
                        <mat-label>Gender</mat-label>
                        <mat-select formControlName="gender">
                            <mat-option *ngFor="let item of genders" [value]="item.genderName">
                                {{item.genderName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            </div>
        </form>
    </div>
</div>