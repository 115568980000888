<ngx-slick-carousel class="offer-slider carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides; let i = index" class="slide" [ngClass]="{'color1': i % 3 === 0, 'color2': i % 3 === 1, 'color3': i % 3 === 2}">
        <a class="pointer" [href]="routerLink(slide)">
            <div class="slides">
                <div class="slide-image"
                    [ngStyle]="{background: 'url(' + slide?.image + ')' + 'no-repeat center / cover,#cccccc'}">
                </div>
                <div class="slider-content">
                    <div class="heading_28">
                        {{slide.heading}}
                    </div>
                    <div class="description">
                        Save Up to
                    </div>
                    <div class="offer-percentage">
                        {{slide.subHeading}}%<span class="off-size">OFF</span>
                    </div>
                </div>
            </div>
        </a>
    </div>
</ngx-slick-carousel>