import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/service/auth.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/service/home.service';
import { Apollo } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'trpx-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  @Input() componentName: string = '';
  @Input() landingLogo: any;
  @Input() showHeader: boolean | undefined;

  toggleSearch: boolean = false;
  isLogin: boolean = false;
  countryList: any;
  userName: string = '';
  customerProfile = this.authService.getCustomer();
  showSubMenu = false;
  @ViewChild('signin', { static: true })
  signin!: ElementRef;
  showCountryDrop = false;
  showCurrencyDrop = false;
  showCountryDropMob = false;
  showCurrencyDropMob = false;
  @Input() activeTab: string = '';
  tripLogo: string = '/assets/icons/logo.webp';
  logoSrc!: string;
  checkingWhiteLabel: boolean = false;

  modalRef?: BsModalRef;
  timestamp = new Date().getTime();

  loginForm: any = FormGroup;
  private dataSubscription: Subscription = new Subscription();

  countryDetailsData: any;
  currencyList: any = [];
  selectedCountry: any = localStorage.getItem('Trip_Country') ?? null;
  selCountryCode: any = localStorage.getItem('Trip_CountryCode') ?? null;
  selectedCurrency: any = localStorage.getItem('Trip_Currency') ?? null;
  travelAgent: any = localStorage.getItem('Trip_Travel_Agent') ?? null;
  selectedCountryName: any;
  isCountryApiCalled = false;
  isCurrencyApiCalled = false;
  isReloaded = false;
  cartCount = 0;
  baseUrl: string;
  homeUrl: string = '/';


  constructor(
    private apollo: Apollo,
    public router: Router,
    public homeService: HomeService,
    private modalService: BsModalService,
    public authService: AuthService,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private route: ActivatedRoute
  ) {
    this.dataSubscription = this.homeService.country$.subscribe((data) => {
      this.selectedCountry = data;
      if (this.selectedCountry) this.setCountryName();
    });

    this.dataSubscription = this.homeService.currency$.subscribe((data) => {
      this.selectedCurrency = data;
    });

    this.getCountryDetails();
    this.getCurrencyDetails();

    this.dataSubscription = this.authService.customers$.subscribe((data) => {
      if (data == '404') {
        this.openModal(this.signin);
        this.authService.logout();
      } else this.customerProfile = data;
    });

    this.dataSubscription = this.homeService.cartCount$.subscribe((data) => {
      this.cartCount = data;
    });

    this.route.queryParams.subscribe((params) => {
      this.activeTab = params['type'];
    });
    this.baseUrl = this.getHostURL();
    if (this.baseUrl != environment.websiteOrigin && !this.baseUrl.includes('clubmahindra')) {
      const whiteLabelCalled = localStorage.getItem('whiteLabelCalled');
      if (!whiteLabelCalled) {
      this.getWhiteLabelDetails();
      }
    } else {
      localStorage.removeItem('whiteLabelCalled');
      localStorage.removeItem('white_label_source');
      localStorage.removeItem('logoSrc');
    }
  }

  ngOnInit(): void {
    this.customerProfile = this.authService.getCustomer();
    this.cartCount = this.customerProfile?.cart_count || 0;
    this.checkIfTokenValid();
    this.getQueryParameters();
  }

  getLogo(){
    return localStorage.getItem('logoSrc') || this.logoSrc || this.tripLogo;
  }

  getHostURL(): string {
    return window.location.origin;
  }

  getWhiteLabelDetails() {
    this.checkingWhiteLabel = true;
    this.logoSrc = this.tripLogo;
    this.authService.whiteLabelValidation().subscribe((response) => {
      this.logoSrc = response?.logo || this.tripLogo;
      this.homeUrl = response?.logo_href || this.homeUrl;
      const whiteLabelCalled = localStorage.getItem('whiteLabelCalled');
      // if (!whiteLabelCalled) {
      //   if (this.homeUrl != this.router.url) this.router.navigate([this.homeUrl]);
      // }
      this.authService.setLogo(response?.logo);
      this.authService.setCustomer(response?.customer_profile);
      this.authService.setSource(response?.source);

      localStorage.setItem('whiteLabelCalled', 'true');
      localStorage.setItem('logoSrc', this.logoSrc);
    });
  }

  getQueryParameters() {
    let tokenId;
    this.route.queryParams.subscribe((params: any) => {
      tokenId = params?.token;
      if (tokenId && !this.baseUrl.includes('yesrewards')) {
        let token = {
          token: tokenId,
        };
        this.logoSrc = '';
        this.authService.clubmahindraValidation(token).subscribe((response) => {
          if (response.status == 'fail') {
            this.authService.logout();
            return;
          }
          this.customerProfile = response?.customer_profile;
          this.authService.setToken(response?.token?.access);
          this.authService.setRefreshToken(response?.token?.refresh);
          this.authService.setCustomer(response?.customer_profile);
          this.logoSrc = response?.logo || this.tripLogo;
          this.authService.setLogo(response?.logo);
          if (!this.isReloaded) {
            this.reloadCurrentRoute();
            this.isReloaded = true;
          }
        });
      } else {
        const details = this.authService.checkIfCMCustomerExists();
        if (details) {
          this.logoSrc = this.authService.getLogo() || this.tripLogo;
        }
        else {
          this.logoSrc = !this.checkingWhiteLabel ? this.tripLogo : '';
        }
      }
    });
  }

  checkIfTokenValid() {
    const token = localStorage.getItem('token');
    const refreshTime = localStorage.getItem('refreshtime');

    if (refreshTime && token) {
      // const currentTime = new Date().getTime();
      // const differenceInHours = (currentTime - parseInt(refreshTime, 10)) / (1000 * 60 * 60);

      // if (differenceInHours > 18) {
      const parsedToken = JSON.parse(token);
      this.authService
        .checkIfTokenValid(parsedToken)
        .subscribe((result: any) => {
          if (!result.is_valid) {
            this.authService.logout();
          }
        });
      // }
    }
  }

  checkRefreshToken(): void {
    let refreshtime = localStorage.getItem('refreshtime') || '0';
    if (this.authService.isRefreshToken()) {
      if (new Date().getTime() - parseInt(refreshtime) > 6.48e7) {
        this.getTokenRefresh();
      }
    }
  }

  getTokenRefresh() {
    this.authService
      .getTokenFromRefresh({
        refresh: localStorage.getItem('refreshtoken'),
      })
      .subscribe((result) => {
        if (result?.access) {
          this.authService.setToken(result?.access);
          localStorage.setItem(
            'refreshtime',
            JSON.stringify(new Date().getTime())
          );
        } else this.logout();
      });
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  setCountryName() {
    // let country = this.countryDetailsData.find((ae: any) => ae.id == this.selectedCountry);
    // this.selectedCountryName = country?.countryName;
    let country = this.countryDetailsData?.find(
      (ae: any) => ae.id == this.selectedCountry
    );
    if (country) {
      this.selectedCountryName = country?.countryName;
    } else {
      // Handle the case where country is not found
      // console.error('Country not found for id:', this.selectedCountry);
    }
  }

  getCountryDetails() {
    this.apollo
      .watchQuery({
        query: this.homeService.country,
      })
      .valueChanges.subscribe(({ data }: any) => {
        this.countryDetailsData = data?.country;
        this.homeService.updateCountryList(this.countryDetailsData);
        localStorage.setItem('Trip_Country', this.countryDetailsData[0]?.id);
        localStorage.setItem(
          'Trip_CountryCode',
          this.countryDetailsData[0]?.countryCode
        );
        this.selectedCountry = this.countryDetailsData[0]?.id;
        this.selCountryCode = this.countryDetailsData[0]?.countryCode;
        this.selectedCountryName = this.countryDetailsData[0]?.countryName;
        this.homeService.updateCountry(this.countryDetailsData[0]?.id);
        // }
      });
  }

  getCurrencyDetails() {
    this.apollo
      .watchQuery({
        query: this.homeService.currency,
      })
      .valueChanges.subscribe(({ data }: any) => {
        const currentTime = Date.now();
        const previousSavedTime = localStorage.getItem('currencyTime') || '0';

        localStorage.setItem('currencyTime', JSON.stringify(currentTime));
        if (currentTime - JSON.parse(previousSavedTime) > 259200000)
          localStorage.removeItem('Trip_Currency');

        this.currencyList = data?.currency;
        this.homeService.updateCurrencyList(this.currencyList);
        this.selectedCurrency = localStorage.getItem('Trip_Currency');

        if (this.selectedCurrency) {
          this.homeService.updateCurrency(this.selectedCurrency);
        } else {
          localStorage.setItem('Trip_Currency', this.currencyList[0]?.code);
          this.selectedCurrency = this.currencyList[0]?.code;
          this.homeService.updateCurrency(this.currencyList[0]?.code);
        }
      });
  }

  changeCountry(country: string) {
    this.selectedCountry = country;
    this.homeService.updateCountry(this.selectedCountry);
    const COUNTRY = this.countryDetailsData.find(
      (ae: any) => ae.id == this.selectedCountry
    );
    this.selCountryCode = COUNTRY?.countryCode;
    // this.setCountryName();
    localStorage.setItem('Trip_CountryCode', COUNTRY?.countryCode);
    this.showCountryDrop = false;
    this.showCountryDropMob = false;
    // this.reloadCurrentRoute();
  }

  changeCurrency(currency: any) {
    this.selectedCurrency = currency;
    this.homeService.updateCurrency(this.selectedCurrency);
    const CURRENCY = this.currencyList.find(
      (ae: any) => ae.code == this.selectedCurrency
    );
    this.selectedCurrency = CURRENCY?.code;
    this.showCurrencyDrop = false;
    this.showCurrencyDropMob = false;
    this.reloadCurrentRoute();
  }

  openModal(template: any) {
    const modalConfig = {
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, {
      ...modalConfig,
      class: 'modal-dialog-centered',
      animated: true,
    });
    this.modalRef.onHide?.subscribe((reason: string | any) => {
      this.authService.updateLoginPopShown(false);
    });
  }

  closeModal() {
    this.modalRef?.hide();
  }

  onlogin(data: any) { }

  goToCart() {
    if (this.customerProfile) {
      this.router.navigate([`/cart`]);
    } else {
      this.openModal(this.signin);
    }
  }

  goToWishlist() {
    if (this.customerProfile) {
      this.router.navigate([`/profile`], { queryParams: { tab: 'wishlist' } });
    } else {
      this.openModal(this.signin);
    }
  }

  closeMenu() {
    this.removeMenuWidth();
  }

  displayMenu() {
    this.addMenuWidth();
  }

  addMenuWidth() {
    let elem = document.getElementById('menu');
    if (elem?.classList.contains('menu_width_none')) {
      elem.classList.remove('menu_width_none');
      elem.classList.add('menu_width');
    }
  }

  removeMenuWidth() {
    let elem = document.getElementById('menu');
    if (elem?.classList.contains('menu_width')) {
      elem.classList.remove('menu_width');
      elem.classList.add('menu_width_none');
    }
  }

  toggleMenuWidth() {
    let elem = document.getElementById('menu');
    if (elem) {
      if (elem.classList.contains('menu_width')) {
        elem.classList.remove('menu_width');
        elem.classList.add('menu_width_none');
      } else if (elem.classList.contains('menu_width_none')) {
        elem.classList.remove('menu_width_none');
        elem.classList.add('menu_width');
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.header') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('header-inverse');
    } else {
      element.classList.remove('header-inverse');
    }
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const dropdownCountryMob = this.elementRef.nativeElement.querySelector(
      '#dropdownCountryMob'
    );
    if (
      dropdownCountryMob &&
      !dropdownCountryMob.contains(event.target as Node)
    ) {
      this.showCountryDropMob = false;
    }

    const dropdownCurrencyMob = this.elementRef.nativeElement.querySelector(
      '#dropdownCurrencyMob'
    );
    if (
      dropdownCurrencyMob &&
      !dropdownCurrencyMob.contains(event.target as Node)
    ) {
      this.showCurrencyDropMob = false;
    }

    const dropdownCountry =
      this.elementRef.nativeElement.querySelector('#dropdownCountry');
    if (dropdownCountry && !dropdownCountry.contains(event.target as Node)) {
      this.showCountryDrop = false;
    }

    const dropdownCurrency =
      this.elementRef.nativeElement.querySelector('#dropdownCurrency');
    if (dropdownCurrency && !dropdownCurrency.contains(event.target as Node)) {
      this.showCurrencyDrop = false;
    }
  }

  hideSubmenu() {
    setTimeout(() => {
      this.showSubMenu = false;
    }, 1000);
  }

  logout(isManual?: boolean) {
    this.authService.updateCustomer(null);
    if (isManual) this.toastr.warning('Logged Out Successfully!');
    this.authService.logout();
    let currentURL = this.router.url;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigateByUrl(currentURL));
    if (this.travelAgent) {
      window.location.reload();
    }
  }

  profilePage() {
    this.router.navigate([`/profile`]);
  }

  searchQuery = '';
  experianceSearchParam() {
    if (this.nullCheck(this.searchQuery)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([`/search-results`], {
          queryParams: { type: 'experiences', experiance: this.searchQuery },
        })
      );
      this.trackSearch();
    } else {
      this.toastr.warning('Please Search Destination');
    }
  }
  nullCheck(vari: any) {
    if (vari != undefined && vari != null && vari != '') {
      return true;
    } else {
      return false;
    }
  }

  trackSearch(): void {
    console.log('Attempting to push search event');
    if (window && window.gtag) {
      window.gtag('event', 'search', {
        search_term: this.searchQuery,
        location: 'search_results',
      });
      console.log("search",{
        search_term: this.searchQuery,
        location: 'search_results',
      });
    } else {
      console.error('gtag function not found');
    }
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

  getCurrencySymbol(currency: any) {
    if (!currency || this.currencyList.length == 0) return '';
    const CURRENCY = this.currencyList.find((ae: any) => ae.code == currency);
    return CURRENCY?.symbol || '';
  }
}
