import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Component({
  selector: 'trpx-offers-card',
  templateUrl: './offers-card.component.html',
  styleUrls: ['./offers-card.component.scss'],
})
export class OffersCardComponent implements OnInit {
  @Input() slides: any;
  @Input() componentName: any;

  constructor(
    public router: Router,
    private locationStrategy: LocationStrategy
  ) {}
  ngOnInit(): void {}

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          dots: true,
        },
      },
    ],
  };
  routerLink(slide: any) {
    if (slide?.redirectionType === 'PRODUCT-PAGE') {
      const productSlug = slide.product?.productSlug;
      let urlTree: UrlTree = this.router.createUrlTree([`/product/${productSlug}`]);
      let url: string = this.router.serializeUrl(urlTree);
  
      if (this.locationStrategy instanceof HashLocationStrategy) {
        const baseHref = this.locationStrategy.getBaseHref();
        url = `${baseHref}#${url}`;
      }
      const hostUrl = window.location.origin;
      return `${hostUrl}${url}`;
    }
  
    if (slide?.redirectionType === 'ATTRACTION-PASS-PAGE') {
      const attractionSlug = slide?.attractionPass?.slug;
      let urlTree: UrlTree = this.router.createUrlTree([`/attraction-pass/${attractionSlug}`]);
      let url: string = this.router.serializeUrl(urlTree);
  
      if (this.locationStrategy instanceof HashLocationStrategy) {
        const baseHref = this.locationStrategy.getBaseHref();
        url = `${baseHref}#${url}`;
      }
      const hostUrl = window.location.origin;
      return `${hostUrl}${url}`;
    }
  
    // Default return if no conditions match
    return ''; // or you could return null or undefined, depending on your needs
  }
  
}
